/* eslint-disable */
(function(root, factory) {
  // Configuration
  var exportName = 'matchPlacement';

  if (typeof exports === 'object') {
    // Node. Does not work with strict CommonJS, but
    // only CommonJS-like environments that support module.exports,
    // like Node.
    module.exports = factory.call(root, require('./dk5-number.cjs'));
  } else {
    // Browser globals (root is window)
    root[exportName] = factory.call(root, window.DK5);
  }

  // Dependencies passed as arguments
}(this, function(DK5) {
  function get(object, path) {
    if (!object)
      return undefined;

    for (var i = 0, points = path.split("."), _length = points.length; i < _length; i++) {
      object = object[points[i]];

      if (object === undefined)
        return undefined;
    }

    return object;
  }

  function toLowerCase(string) {
    if (typeof string === "string")
      return string.toLowerCase();

    return string;
  }
  /*
   * Find the legend element by query.
   *
   * @params {object[]} legend - The legend object see the scheme of legend in ../../server/lib/db.js.
   * @params {object} query - Options to search legend item.
   * @params {string} query.placement - Name of the placement.
   * @params {string} query.departmentId - Id of department.
   * @params {string} query.department - Name of department.
   * @params {string} query.locationId - Id of location.
   * @params {string} query.location - Name of location.
   * @params {string} query.subLocationId - Id of sub-location.
   * @params {string} query.subLocation - Name of sub-location.
   * @params {string} query.dk5 - dk5 vendor code.
   * @params {string} query.author - author vendor code.
   * @params {string} query.dewey - dewey vendor code.
   * @params {string} query.shelfmark - shelfmark vendor code.
   * @params {string} query.type - the type of the material.
   *
   * @returns {object[]} - List of marks with following format `{id: legendItem.id, metatype: legendItem.metadata.type}`.
   */
  function matchPlacement(legend, query) {
    var markIds = [];

    legend.forEach(function(legendItem) {
      var score = 0;
      if (legendItem.placement) {
        ["departments", "locations", "subLocations"].forEach(function(placementType) {
          if (legendItem.placement[placementType] && !Array.isArray(legendItem.placement[placementType])) {
            legendItem.placement[placementType] = [legendItem.placement[placementType]];
          }
        });
      }

      var departments = query.departmentId || query.department ? get(legendItem, "placement.departments") : null;
      var locations = query.locationId || query.location ? get(legendItem, "placement.locations") : null;
      var subLocations = query.subLocationId || query.subLocation ? get(legendItem, "placement.subLocations") : null;

      /* The selecting of marks will be done only in case if total matching score will be more or equal to max score. */
      var maxScore = [
        query.placement,
        departments,
        locations,
        subLocations,
        query.dk5 || query.author || query.dewey || query.shelfmark || query.type || query.wideRangeMeta,
      ].filter(Boolean).length;

      var metatype;

      /* Mathing by label. */
      Object.keys(legendItem.label).some(function(langCode) {
        if (toLowerCase(legendItem.label[langCode]) === toLowerCase(query.placement)) {
          if (Object.keys(query).length === 1) {
            /*
             * The adding of max score is needed since the location
             * options will not be checked but provided in max score
             * if the only vendor code (dk5, dewey and etc) will be used.
             */
            score += maxScore;
          } else {
            score += 1;
          }
        }
      });

      /* Mathing by vendor code. */
      if ((query.dk5 || query.author || query.dewey || query.shelfmark || query.type || query.wideRangeMeta) && typeof legendItem.metadata === 'object' && legendItem.metadata) {
        var dk5Instance;
        var testStr;

        if (legendItem.metadata.type === 'dk5') {
          dk5Instance = new DK5(legendItem.metadata.value);

          if (query.dk5) {
            testStr = query.dk5;
          }
        } else if (legendItem.metadata.type === 'author') {
          dk5Instance = new DK5.Author(legendItem.metadata.value);

          if (query.author) {
            testStr = query.author;
          }
        } else if (legendItem.metadata.type === 'dewey') {
          dk5Instance = new DK5.Dewey(legendItem.metadata.value);

          if (query.dewey) {
            testStr = query.dewey;
          }
        } else if (legendItem.metadata.type === 'shelfmark') {
          dk5Instance = new DK5.Shelfmark(legendItem.metadata.value);

          if (query.shelfmark) {
            testStr = query.shelfmark;
          }
        } else if (legendItem.metadata.type === 'type') {
          dk5Instance = new DK5.Type(legendItem.metadata.value);

          if (query.type) {
            testStr = query.type;
          }
        }

        if (query.wideRangeMeta && !testStr) {
          testStr = query.wideRangeMeta;
        }

        if (dk5Instance && testStr && dk5Instance.test(testStr)) {
          metatype = legendItem.metadata.type;
          score += 1;
        }
      }

      /* Mathing by placements. */
      if (departments) {
        departments.some(function(itemDepartment) {
          var foundDepartmentName = toLowerCase(itemDepartment.name) === toLowerCase(query.department);
          var foundDepartmentId = toLowerCase(itemDepartment.id) === toLowerCase(query.departmentId);
          if (foundDepartmentName || foundDepartmentId) {
            score += 1;
            return true;
          }
        });
      }

      if (locations) {
        locations.some(function(itemLocation) {
          var foundLocationName = toLowerCase(itemLocation.name) === toLowerCase(query.location);
          var foundLocationId = toLowerCase(itemLocation.id) === toLowerCase(query.locationId);
          if (foundLocationName || foundLocationId) {
            score += 1;
            return true;
          }
        });
      }

      if (subLocations) {
        subLocations.some(function(itemSubLocation) {
          var foundSubLocationName = toLowerCase(itemSubLocation.name) === toLowerCase(query.subLocation);
          var foundSubLocationId = toLowerCase(itemSubLocation.id) === toLowerCase(query.subLocationId);
          if (foundSubLocationName || foundSubLocationId) {
            score += 1;
            return true;
          }
        });
      }

      /* Checking of matching result. */
      if (score >= maxScore && maxScore > 0) {
        if (markIds.indexOf(legendItem.id) === -1) {
          markIds.push({ id: legendItem.id, metatype: metatype });
        }
      }

      /* Adding the sub legend findings to end result. */
      if (Array.isArray(legendItem.sub)) {
        markIds = markIds.concat(matchPlacement(legendItem.sub, query));
      }
    });

    return markIds;
  };

  /*
   * Wrapper for match function allows to find marks in any map of view.
   *
   * @params {object[]} maps - List of maps on view, see the scheme in ../../server/lib/db.js.
   * @params {object} query - Options to search legend item, see the scheme in matchPlacement function.
   *
   * @return {object} - The map name and list of found marks in following format `{ markId: string, mapName: string }`, where `markId` - it's comma separated list of found mark ids.
   */
  return function(maps, query) {
    /*
     * Fallback action for "empty" dk5 value.
     * See related task: https://inlead.atlassian.net/browse/ES-1516
     */
    if (query.dk5 === 'sk') {
      delete query.dk5;
    }

    if (!maps || !Array.isArray(maps)) {
      return null;
    }

    var markId = '';
    var mapName = '';

    maps.some(function(map) {
      mapName = map.name;
      if (Array.isArray(map.legend)) {
        var hasDeweyOrDK5 = false;
        var foundByAuthor = [];

        var markIds = matchPlacement(map.legend, query).map(function(mark, index) {
          if (mark.metatype === 'author') {
            foundByAuthor.push(index);
          } else if (mark.metatype === 'dk5' || mark.metatype === 'dewey') {
            hasDeweyOrDK5 = true;
          }

          return mark.id;
        });

        if (hasDeweyOrDK5) {
          markIds = markIds.filter(function(id, index) {
            if (foundByAuthor.indexOf(index) === -1) {
              return true;
            }
          });
        }

        markId = markIds.join(',');
      }

      return markId;
    });

    return markId ? { markId: markId, mapName: mapName } : null;
  };
}));
/* eslint-enable */
