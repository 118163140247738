<template>
  <div :class="[
    'wayfinder-layout--content',
    `wayfinder-layout_${ theme }`,
    { 'wayfinder-layout--content_extended': !sidebarOpened },
    orientation
  ]">
    <wayfinder-map-list />
  </div>
</template>

<style src="./layout.less" lang="less"></style>
<script>
  import { mapState, mapActions } from "vuex";
  import orientationMixin from "../mixins/orientation.js";

  import WayfinderMapList from "../map/map-list.vue";

  export default {
    name: "wayfinder-layout--content",
    mixins: [orientationMixin],
    computed: {
      ...mapState({
        sidebarOpened: state => state.sidebarOpened,
        useTransition: state => state.useTransition,
        theme: state => state.theme
      })
    },
    methods: {
      ...mapActions({
        _disableTransition: "disableTransition",
        _enableTransition: "enableTransition"
      })
    },
    mounted() {
      this.$on("before-orientation-change", this._disableTransition);
      this.$on("orientation-changed", this._enableTransition);
    },
    beforeDestroy() {
      this.$off("before-orientation-change", this._disableTransition);
      this.$off("orientation-changed", this._enableTransition);
    },
    components: {
      WayfinderMapList
    }
  };
</script>
